import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

import {
  narrow,
  control,
  success,
  cart,
  cartCornerModal,
  cartCornerModalBtn,
  cartCornerModalBtnClose,
  show,
} from 'styles/pages/digitec/cart.module.scss';
import 'styles/pages/digitec/general.scss';

import SEO from 'sections/Layout/SEO';

const DigitacCart = () => {
  const [cornerModal, setRornerModal] = useState(false);

  const setCornerModalOpen = useCallback(() => {
    setRornerModal(true);
  }, []);

  const setCornerModalClose = useCallback(() => {
    setRornerModal(false);
  }, []);

  const seo = {
    title: 'digitec.ch Survey | Promo | Screver Feedback Management Solution',
    metaDesc:
      'Look at the digitec.ch website with our Screver Feedback Survey. It allows you to attract, collect, analyze in real-time, and implement data-driven actions.',
    opengraphUrl: 'https://screver.com/cases/cx/cart/',
  };

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap"
          rel="stylesheet"
        />

        <link
          rel="preload"
          href="https://go.screver.com/screver-playground/digitec-purchase-feedback"
          as="document"
        ></link>
      </Helmet>

      <SEO seo={seo} themeColor="#ffffff" />

      <div className={classnames(cart, 'scr-digitect-wrap')}>
        <button
          className={cartCornerModalBtn}
          onMouseEnter={setCornerModalOpen}
        ></button>

        <div
          className={classnames(
            cartCornerModal,
            'popover-container',
            cornerModal ? show : ''
          )}
        >
          <iframe
            src="https://go.screver.com/screver-playground/digitec-leave"
            title="Survey"
          ></iframe>
        </div>
        <button
          className={classnames(
            cartCornerModalBtnClose,
            cornerModal ? show : ''
          )}
          onClick={setCornerModalClose}
        ></button>

        <main>
          <img
            src="https://wp.screver.com/wp-content/uploads/2023/08/digitec-cart-header.png"
            alt=""
          />
          <img
            src="https://wp.screver.com/wp-content/uploads/2023/08/digitec-cart-top.png"
            alt=""
          />
          <img
            src="https://wp.screver.com/wp-content/uploads/2023/08/digitec-cart-bottom.png"
            alt=""
            className={narrow}
          />
          <div className={classnames(control, narrow)}>
            <img
              src="https://wp.screver.com/wp-content/uploads/2023/08/digitec-cart-check.png"
              alt=""
            />

            <Link to="/cases/cx/digitec/success" className={success}>
              Buy now
            </Link>
          </div>

          <img
            src="https://wp.screver.com/wp-content/uploads/2023/08/digitec-footer.png"
            alt=""
          />
        </main>
      </div>
    </>
  );
};

export default DigitacCart;
